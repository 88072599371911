/** @jsx jsx */
import Article from '../../../template/article'
import { jsx } from '@emotion/core'

const ArticlePage = ({ location }) => (
  <Article location={location}>
    <p>
      手のひらに乗るようなデバイスが、大昔のスパコン以上の性能を持つ現代だが、それでもまだ太古の名残が残り続けている。古いものに縛られて、ユーザーに不便を強要するプログラミングは蛮族の行いだ。
    </p>
    <p>少し話を変えよう。</p>
    <p>僕がJavaScriptをやる理由は、それがユニバーサルな言語だからだ。サーバーも問題なく書けるし、デスクトップアプリの定番はElectronだ。モバイル向けは乱立しているし理想には遠いが、ReactNativeやPWAがある。</p>
    <p>僕は基盤的な要素では大きなものに巻かれればいいと思ってる。それがプロプラならともかく、今のデファクトは大体オープンだから、フォークするなり移行するなりは可能だ。牙を研ぎ澄ますなら、それは自分のやりたいことの本質に向けるべきだ。</p>
    <p>たとえば僕が今やっている個人プロダクトはドキュメンテーションを改革しようというものだ。</p>
    <p>ドキュメンテーションではMarkdownがデファクトスタンダードだ。今もっとも現実的で良くできたクソだ。過去に登場した他のフォーマットに機能は足りないが、利用者の多さと可用性で見ればバランスがいい。</p>
    <p>人類が簡易マークアップ言語と、40年以上戦い続けて、結局何かしらを諦めるしかないようなクソしか生み出せていないのだから、ドキュメンテーションを革命するのに必要な争点は、決して文法ではないということだ。ところが20年以上前のWikiWikiWebの延長線上にあるようなテキストサービスが、未だに作られ続けている。</p>
    <p>そもそもユーザーが文法を覚えなければいけないなんていう野蛮な仕組み自体が、性能やプログラミングパラダイムの事情で、クソなUIしか生み出せなかった頃の妥協の産物だ。利用者にそれを強要するのは蛮族プログラミングだ。</p>
    <p>JavaScriptもいってみれば、一番マシなクソだ。ES5やその前からの負債もあるし、今後も決してベストではないかもしれないが、プログラミング言語なんてよくできたクソで十分だ。</p>
    <p>蛮族プログラミングを卒業して、もっと本質と向き合おう。</p>
    <p>僕は、現代だからこそ作られる、もっと革命的でわくわくするようなプロダクトを自分の手で生み出したい。</p>
    <p>あなたはどうしたい？</p>
  </Article>
)

export default ArticlePage
